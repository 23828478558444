<template>
	<item-selector
		:label="'concept' | trans"
		outlined
		dense
		:modal="modal"
		title="description"
		description="hint"
		action="type"
		:items="items"
		v-model="selected"
		:track-by="null"
		@input="emit"
	></item-selector>
</template>
<script>
import { getDevengables, getDeducibles } from "../services/utils";
export default {
	async mounted() {
		this.concepts = await this.api.load("concepts");
	},
	methods: {
		emit(event) {
			this.$emit("input", event);
		},
	},
	data() {
		return {
			concepts: [],
			selected: null,
		};
	},
	props: {
		mode: {
			type: String,
		},
		item: {
			type: Object,
		},
		value: {
			type: [Number, Object],
		},
		modal: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		items() {
			let worker;
			if (this.item) {
				worker = this.item.worker
					? this.item.worker
					: this.item.worker_id &&
					  this.api.objects["workers?scope[actives]=1"] &&
					  this.api.objects["workers?scope[actives]=1"].collection
					? this.api.objects["workers?scope[actives]=1"].collection[this.item.worker_id]
					: null;
			}
			let list;
			if (this.mode === "devengables") {
				list = getDevengables(this.concepts, worker);
			} else if (this.mode === "deducibles") {
				list = getDeducibles(this.concepts, worker);
			} else {
				list = [...getDevengables(this.concepts, worker), ...getDeducibles(this.concepts, worker)];
			}
			return list.map((l) => ({ ...l, hint: this.$options.filters.money(l.value) }));
		},
	},
	watch: {
		"item.concept_id": {
			immediate: true,
			async handler() {
				let find;
				this.concepts = await this.api.load("concepts");
				if (this.item.concept_id == null) {
					find = this.items.find((item) => item.description === this.item.description);
				} else {
					find = this.items.find((item) => item.id === this.item.concept_id);
				}
				if (find) this.selected = find;
			},
		},
	},
};
</script>

<template>
	<v-dialog
		v-model="dialog"
		scrollable
		persistent
		no-click-animation
		max-width="80%"
		:fullscreen="fullscreen || $vuetify.breakpoint.smAndDown"
		transition="dialog-transition"
	>
		<crud-editor v-if="item" :headers="headers" :item="item" :save-action="save" :cancel-action="cancel">
			<template v-slot:title>
				{{ item.id ? "edit" : "add" | trans("crud") }}
				{{ "novelty" | trans("literals") }}
			</template>
		</crud-editor>
	</v-dialog>
</template>
<script>
import Vue from "vue";
import model from "../models/novelties";
import CrudEditor from "../components/main/CrudEditor";
import ConceptSelector from "../components/ConceptSelector";
import moment from "moment";
export default {
	components: { CrudEditor },
	mounted() {
		Vue.component("concept-selector", ConceptSelector);
	},
	data: () => ({
		item: null,
		headers: model.headers,
		dialog: false,
	}),
	methods: {
		async save(item) {
			const data = {
				id: item.id,
				worker_id: item.worker_id,
				description: item.description,
				concept_id: item.concept_id || (item.concept && item.concept.id),
				quantity: item.quantity,
				is_salarial: item.is_salarial,
				value: item.value,
				date: item.date,
			};
			const withDays = [
				"salario basico",
				"vacacion",
				"vacacion compensada",
				"licencia",
				"licencia remunerada",
				"licencia no remunerada",
				"licencia paternidad",
				"incapacidad",
				"incapacidad enfermedad general",
				"incapacidad enfermedad laboral",
				"incapacidad por accidente de trabajo",
			];
			if (withDays.includes(item.description.toLowerCase())) {
				data.end_date = moment(data.date)
					.add(item.quantity - 1, "days")
					.format("YYYY-MM-DD");
			}
			if (item.concept && item.concept.id.startsWith("dynamic")) data.concept_id = null;
			let response = null;
			this.$emit("saving", data);
			if (data.id) {
				response = await this.api.put(`novelties/${data.id}`, data);
			} else {
				response = await this.api.post(`novelties`, data);
			}
			this.$emit("saved", response);
			this.item = null;
			this.cancel();
		},
		cancel() {
			this.$emit("cancel");
			this.dialog = false;
		},
	},
	watch: {
		value: {
			immediate: true,
			handler() {
				this.dialog = !!this.value;
				if (this.value) this.item = JSON.parse(JSON.stringify(this.value));
			},
		},
		"item._concept": {
			immediate: true,
			handler() {
				if (this.item && this.item._concept) {
					this.$set(this.item, "description", this.item._concept.description);
					this.$set(this.item, "type", this.item._concept.type);
					this.$set(this.item, "value_type", this.item._concept.value_type);
					this.$set(this.item, "value", this.item._concept.value);
					this.$set(this.item, "is_salarial", !!this.item._concept.is_salarial);
				}
			},
		},
	},
	props: {
		value: {
			type: Object,
		},
		fullscreen: {
			default: false,
		},
		saveAction: {
			type: Function,
		},
	},
};
</script>

<template>
	<v-container fluid>
		<v-layout row wrap class="mt-4 mx-2">
			<v-flex class="pa-3" hidden-xs sm8 md9 lg10 layout row>
				<template v-if="api.user.cliente">
					<img class="mx-1" height="55" contain :src="api.user.cliente.foto_url" />
					<div class="subtitle-2 layout column flex-1">
						{{ api.user.cliente.full_name }}
						<small class="caption">{{ api.user.cliente.document }}</small>
					</div>
				</template>
				<template v-else>
					<img height="55" contain :src="api.url + 'img/logo.png'" />
					<div class="subtitle-2 layout column">
						{{ api.user.app_name }}
						<small class="caption">{{ api.user.full_name }}</small>
					</div>
				</template>
			</v-flex>
			<v-flex xs12 sm4 md3 lg2>
				<v-text-field
					type="month"
					v-model="date"
					@input="changeDates"
					outlined
					prepend-inner-icon="event"
					required
					single-line
					:label="'date' | trans"
				></v-text-field>
			</v-flex>
		</v-layout>
		<v-layout row wrap class="mx-2">
			<!-- Summary Cards -->
			<!-- Summary Nomina -->
			<v-flex xs12 sm3 md3 lg3 class="pa-1" v-can="'Administrar Trabajadores'">
				<v-card
					hover
					class="px-3 flex"
					style="margin-top: 30px"
					:to="`/nomina/${start.format('YYYY')}/${start.format('MM')}`"
				>
					<v-card-title>
						<div class="orange pa-3 elevation-5" style="margin-top: -60px">
							<v-icon large color="white">mdi-cash-multiple</v-icon>
						</div>
						<v-spacer></v-spacer>
						<span class="grey--text subtitle-1 text-capitalize">{{ dateLabel }}</span>
					</v-card-title>
					<v-divider></v-divider>
					<v-card-actions>
						<span class="subtitle-2">{{ "Nomina Esperada" | trans }}</span>
						<v-spacer></v-spacer>
						<span class="body-1">{{ nominaExpected | money }}</span>
					</v-card-actions>
				</v-card>
			</v-flex>
			<!-- Summary Workers -->
			<v-flex xs12 sm3 md3 lg3 class="pa-1" v-can="'Administrar Trabajadores'">
				<v-card hover class="px-3 flex" style="margin-top: 30px" to="/workers">
					<v-card-title>
						<div class="primary pa-3 elevation-5" style="margin-top: -60px">
							<v-icon large color="white">mdi-account-hard-hat</v-icon>
						</div>
						<v-spacer></v-spacer>
						<span class="grey--text subtitle-1 text-capitalize">{{ "actives" | trans }}</span>
					</v-card-title>
					<v-divider></v-divider>
					<v-card-actions>
						<span class="subtitle-2">{{ "workers" | trans }}</span>
						<v-spacer></v-spacer>
						<span class="body-1">{{ workers.length }}</span>
					</v-card-actions>
				</v-card>
			</v-flex>
			<!-- Summary Concepts -->
			<v-flex xs12 sm3 md3 lg3 class="pa-1" v-can="'Administrar Conceptos'">
				<v-card hover class="px-3 flex" style="margin-top: 30px" to="/concepts">
					<v-card-title>
						<div class="blue pa-3 elevation-5" style="margin-top: -60px">
							<v-icon large color="white">mdi-list-status</v-icon>
						</div>
						<v-spacer></v-spacer>
						<span class="grey--text subtitle-1 text-capitalize">{{ "quantity" | trans }}</span>
					</v-card-title>
					<v-divider></v-divider>
					<v-card-actions>
						<span class="subtitle-2">{{ "concepts" | trans }}</span>
						<v-spacer></v-spacer>
						<span class="body-1">{{ concepts.length }}</span>
					</v-card-actions>
				</v-card>
			</v-flex>
			<!-- Summary Novelties -->
			<v-flex xs12 sm3 md3 lg3 class="pa-1" v-can="'Administrar Novedades'">
				<v-card hover class="px-3 flex" style="margin-top: 30px" to="/novelties">
					<v-card-title>
						<div class="red pa-3 elevation-5" style="margin-top: -60px">
							<v-icon large color="white">mdi-emoticon-sick</v-icon>
						</div>
						<v-spacer></v-spacer>
						<span class="grey--text subtitle-1 text-capitalize">{{ dateLabel }}</span>
					</v-card-title>
					<v-divider></v-divider>
					<v-card-actions>
						<span class="subtitle-2">{{ "novelties" | trans }}</span>
						<v-spacer></v-spacer>
						<span class="body-1">{{ novelties.length }}</span>
					</v-card-actions>
				</v-card>
			</v-flex>

			<!-- Calendar -->
			<v-flex xs12 class="pa-1">
				<v-card hover :loading="!ready">
					<v-card-title>
						<div>{{ "calendar" | trans }}</div>
						<v-spacer></v-spacer>
						<span class="grey--text subtitle-1 text-capitalize">{{ dateLabel }}</span>
					</v-card-title>
					<v-card-text>
						<incapacidad-editor @saved="getData" v-model="newIncapacidad"></incapacidad-editor>
						<novelty-editor @saved="getData" v-model="newNovelty"></novelty-editor>
						<v-sheet height="600">
							<v-calendar
								event-ripple
								:start="start.format('YYYY-MM-DD')"
								:end="end.format('YYYY-MM-DD')"
								ref="calendar"
								event-more
								@click:day="
									selectNoveltyType = true;
									selectedDate = $event.date;
									selectedEndDate = $event.date;
								"
								@click.native="getCoordinates"
								@click:event="showEvent"
								@click:more="showEvents($event)"
								:events="events"
								:event-color="eventColor"
							></v-calendar>
						</v-sheet>
					</v-card-text>
				</v-card>
			</v-flex>
			<!-- Menu Add -->
			<v-menu
				v-model="selectNoveltyType"
				absolute
				offset-y
				max-height="250"
				:close-on-content-click="true"
				:position-x="x"
				:position-y="y"
			>
				<v-card flat v-if="selectNoveltyType">
					<v-layout>
						<v-list dense>
							<v-list-item @click="addIncapacidad">
								<v-list-item-content>
									<v-list-item-title class="subtitle-2"
										>{{ "crud.add" | trans }} {{ "Incapacidad" | trans }}</v-list-item-title
									>
								</v-list-item-content>
							</v-list-item>
							<v-divider></v-divider>
							<v-list-item @click="addNovelty">
								<v-list-item-content>
									<v-list-item-title class="subtitle-2"
										>{{ "crud.add" | trans }} {{ "novelty" | trans }}</v-list-item-title
									>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-layout>
				</v-card>
			</v-menu>
			<!-- Menu Event -->
			<v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement">
				<v-card flat v-if="selectedEvent">
					<v-toolbar dense dark :color="eventColor(selectedEvent)">
						<div class="flex subtitle-2">{{ selectedEvent.number || selectedEvent.titulo }}</div>
						<v-icon right>{{
							selectedType == "devengado" ? "mdi-chart-line-variante" : "mdi-chart-donut-variant"
						}}</v-icon>
					</v-toolbar>
					<v-layout>
						<v-list dense>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title class="subtitle-2">{{ "type" | trans }}</v-list-item-title>
								</v-list-item-content>
								<v-list-item-action class="subtitle-1">{{ selectedEvent.type }}</v-list-item-action>
							</v-list-item>
							<v-list-item v-if="workers.collection[selectedEvent.worker_id]">
								<v-list-item-content>
									<v-list-item-title class="subtitle-2">{{ "worker" | trans }}</v-list-item-title>
								</v-list-item-content>
								<v-list-item-action class="subtitle-1">{{
									workers.collection[selectedEvent.worker_id].full_name
								}}</v-list-item-action>
							</v-list-item>
							<v-list-item v-if="selectedEvent.value">
								<v-list-item-content>
									<v-list-item-title class="subtitle-2">{{ "total" | trans }}</v-list-item-title>
								</v-list-item-content>
								<v-list-item-action class="subtitle-1">{{
									(selectedEvent.value * selectedEvent.quantity) | money
								}}</v-list-item-action>
							</v-list-item>
						</v-list>
					</v-layout>
				</v-card>
			</v-menu>
			<!-- Menu More -->
			<v-menu
				absolute
				offset-y
				max-height="250"
				v-model="eventsSelected"
				:close-on-content-click="true"
				:position-x="x"
				:position-y="y"
			>
				<v-list dense subheader>
					<v-subheader>
						{{ eventDayClicked }}
						<v-spacer></v-spacer>
						<v-icon small @click="eventsSelected = false">close</v-icon>
					</v-subheader>
					<v-list-item
						v-for="ev in selectedEvents"
						:key="ev.name"
						@click="showEvent({ nativeEvent: $event, event: ev })"
					>
						<v-list-item-title>{{ ev.name }}</v-list-item-title>
						<v-list-item-action>
							<v-icon color="primary">mdi-circle</v-icon>
						</v-list-item-action>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-layout>
	</v-container>
</template>
<script>
// import VueApexCharts from "vue-apexcharts";
import IncapacidadEditor from "./IncapacidadEditor";
import NoveltyEditor from "./NoveltyEditor";
import moment from "moment";
import _ from "lodash";
export default {
	components: { IncapacidadEditor, NoveltyEditor },
	async mounted() {
		await this.api.ready;
		[this.workers, this.concepts] = await Promise.all([
			this.api.load("workers?scope[actives]=1"),
			this.api.load("concepts"),
		]);
		this.getData();
	},
	data() {
		return {
			date: moment().format("YYYY-MM"),
			start: moment().startOf("month"),
			end: moment().endOf("month"),
			selectedEvent: null,
			selectedElement: null,
			selectedOpen: false,
			selectNoveltyType: false,
			selectedDate: null,
			selectedEndDate: null,
			selectedType: "invoice",
			selectedEvents: [],
			workers: [],
			novelties: [],
			concepts: [],
			nomina: [],
			eventsSelected: false,
			eventDayClicked: null,
			newNovelty: null,
			newIncapacidad: null,
			x: 0,
			y: 0,
			ready: false,
		};
	},
	methods: {
		async getData() {
			this.ready = false;
			this.novelties = (
				await this.api.get(`novelties?from=${moment(this.start.format())}&to=${this.end.format()}`)
			).data;
			this.nomina = (await this.api.get(`nominas?from=${moment(this.start.format())}&to=${this.end.format()}`)).data;
			this.ready = true;
		},
		groupData(array, predicate, yValue, mode = "sum") {
			const grouped = _.groupBy(array, predicate);
			const newArray = [];
			for (var k in grouped) {
				const group = grouped[k];
				var item = { x: k };
				if (mode == "sum")
					item.y = group.reduce((sum, i) => {
						return sum + i[yValue];
					}, 0);
				else item.y = group.length;
				newArray.push(item);
			}
			return newArray;
		},
		changeDates() {
			console.log(this.date);
			this.start = moment(this.date + "-01").startOf("month");
			this.end = moment(this.date + "-01").endOf("month");
			this.getData();
		},
		eventColor(ev) {
			if (["devengado"].includes(ev.type)) {
				return "#4caf50";
			}
			if (["others"].includes(ev.type)) {
				return "#F44336";
			}
			if (["deducible"].includes(ev.type)) {
				return "#43a047";
			}
			if (["enviada", "en proceso", "en curso", "parcialmente pagada", "activa"].includes(ev.type)) {
				return "#ffeb3b";
			}
			return "red";
		},
		showEvent({ nativeEvent, event }) {
			this.selectedEvent = event.object;
			this.selectedElement = nativeEvent.target;
			this.selectedType = event.type;
			this.selectedOpen = true;
		},
		showEvents(ev) {
			this.selectedEvents = this.$refs.calendar.getEventsForDay(ev).map((e) => e.input);
			this.eventDayClicked = moment(ev.date).format("LL");
			this.eventsSelected = true;
		},
		getCoordinates(ev) {
			this.x = ev.clientX;
			this.y = ev.clientY;
		},
		addNovelty() {
			if (!this.selectedOpen)
				this.newNovelty = {
					date: this.selectedDate,
					description: "",
					value_type: "monetary",
					type: "devengado",
					quantity: 1,
					concept_id: null,
					is_salarial: false,
				};
		},
		addIncapacidad() {
			if (!this.selectedOpen)
				this.newIncapacidad = {
					description: "Incapacidad Enfermedad General",
					date: this.selectedDate,
					end_date: this.selectedEndDate,
					value_type: "monetary",
					type: "devengado",
					is_salarial: true,
					quantity: 1,
					concept_id: null,
				};
		},
		subtotal(item) {
			return (
				item.devengables.reduce((sum, d) => (sum += d.value * d.quantity), 0) -
				item.deducibles.reduce((sum, d) => (sum += d.value * d.quantity), 0)
			);
		},
	},
	computed: {
		dateLabel() {
			if (this.start.isSame(moment(this.start).startOf("month")) && this.end.isSame(moment(this.start).endOf("month")))
				return this.$options.filters.moment(this.start, "MMMM Y");

			return `${this.$options.filters.moment(this.start, "MMMM Y D")} - ${this.$options.filters.moment(
				this.end,
				"MMMM Y D"
			)}`;
		},
		events() {
			const events = this.novelties.map((n) => {
				var worker = this.workers.collection[n.worker_id];
				return {
					start: moment(n.date).toDate(),
					end: moment(n.end_date || n.date).toDate(),
					name: `${n.description}: ${worker ? worker.full_name : ""} - ${this.$options.filters.money(
						n.value * n.quantity
					)}`,
					total: this.$options.filters.money(n.value * n.quantity),
					estado: n.type,
					type: "novelty",
					object: n,
				};
			});
			return events;
		},
		nominaExpected() {
			if (this.nomina.length) {
				return this.nomina
					.filter((n) => !["eliminado", "reemplazado"].includes(n.status))
					.filter((n) => !["eliminacion"].includes(n.type))
					.reduce((sum, v) => (sum += this.subtotal(v)), 0);
			} else {
				return this.workers.reduce((sum, worker) => (sum += worker.salary), 0);
			}
		},
	},
};
</script>
<style>
.apexcharts-tooltip {
	background: #f3f3f3;
	color: black;
}
</style>

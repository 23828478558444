<template>
	<v-dialog
		v-model="dialog"
		scrollable
		persistent
		no-click-animation
		max-width="80%"
		:fullscreen="fullscreen || $vuetify.breakpoint.smAndDown"
		transition="dialog-transition"
	>
		<crud-editor v-if="item" :headers="headers" :item="item" :save-action="save" :cancel-action="cancel">
			<template v-slot:title>
				{{ item.id ? "edit" : "add" | trans("crud") }}
				{{ "Incapacidad" | trans("literals") }}
			</template>
		</crud-editor>
	</v-dialog>
</template>
<script>
import CrudEditor from "../components/main/CrudEditor";
import moment from "moment";
export default {
	components: { CrudEditor },
	data: () => ({
		item: null,
		headers: [
			{
				text: "description",
				value: "description",
				type: "select",
				tab: "novelty",
				default: "Incapacidad Enfermedad General",
				options: [
					"Incapacidad Enfermedad General",
					"Incapacidad Enfermedad Laboral",
					"Incapacidad por Accidente de Trabajo",
				],
			},
			{
				text: "date.start_time",
				value: "date",
				type: "date",
				tab: "novelty",
			},
			{
				text: "date.end_time",
				value: "end_date",
				type: "date",
				tab: "novelty",
				allowedDates: (date, item) => moment(date).isAfter(item.date),
			},
			{
				text: "worker",
				value: "worker_id",
				type: "relation",
				resource: "workers?scope[actives]=1",
				attribute: "full_name",
				entity: "worker",
				relation: "worker",
				description: "description",
				tab: "novelty",
			},
			{
				text: "value",
				value: "value",
				type: "currency",
				min: 1,
				default: 1,
				step: "any",
				tab: "novelty",
			},
		],
		dialog: false,
	}),
	methods: {
		async save(item) {
			this.$emit("saving", item);
			let response = null;
			this.$set(item, "quantity", Math.abs(moment(item.date).diff(moment(item.end_date), "day")) + 1);
			if (item.id) {
				response = this.api.put(`novelties/${item.id}`, item);
			} else {
				response = this.api.post(`novelties`, item);
			}
			this.$emit("saved", response);
			this.item = null;
			this.dialog = false;
		},
		cancel() {
			this.$emit("cancel");
			this.dialog = false;
		},
	},
	watch: {
		value: {
			immediate: true,
			handler() {
				this.dialog = !!this.value;
				if (this.value) this.item = JSON.parse(JSON.stringify(this.value));
			},
		},
		"item.worker_id": {
			immediate: true,
			handler() {
				if (this.item && this.item.worker_id)
					this.$set(
						this.item,
						"value",
						Math.round(this.api.objects["workers?scope[actives]=1"].collection[this.item.worker_id].salary / 30)
					);
			},
		},
	},
	props: {
		value: {
			type: Object,
		},
		fullscreen: {
			default: false,
		},
	},
};
</script>
